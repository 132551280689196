import { EventActionTypes } from "./events.types";
import { createEventDocument } from "../../firebase/firebase.utils";

const INITIAL_STATE = {};

const eventReducer = async (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case EventActionTypes.GET_EVENTS:
			return { ...state };

		case EventActionTypes.CREATE_EVENT:
			createEventDocument(action.payload.event);
			return { ...state };

		default:
			return state;
	}
};

export default eventReducer;
