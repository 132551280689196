import React from "react";
import { makeStyles } from "@material-ui/styles";

import BeShyPanel from "../../components/be-shy-panel/be-shy-panel.component";

const useStyles = makeStyles({
	homepage: {
		height: "100%",
		margin: "0rem",
		display: "flex",
		alignItems: "center",
		justifyContent: "start"
	}
});

const HomePage = props => {
	const classes = useStyles(props);

	return (
		<div className={classes.homepage}>
			<BeShyPanel />
		</div>
	);
};
export default HomePage;
