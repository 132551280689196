import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { connect, useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useFirestoreConnect } from "react-redux-firebase";

import CustomFab from "../../components/custom-fab/custom-fab.component";
import EventCreationDialog from "../../components/event-creation-dialog/event-creation-dialog.component";
import EventDialog from "../../components/event-dialog/event-dialog.component";

import {
	toggleCreateEventHidden,
	toggleViewEventHidden
} from "../../redux/components/components.actions";
import {
	selectCreateEventHidden,
	selectViewEventHidden
} from "../../redux/components/components.selector";
import { selectEventCollection } from "../../redux/events/events.selector";
import { selectCurrentUser } from "../../redux/user/user.selector";
import {
	getCurrentDatetime,
	getDatetime
} from "../../utils/data/service.utils";

import "./boardgames.styles.scss";
import "./boardgames-calendar.styles.scss";

const localizer = momentLocalizer(moment);

const createTitle = event => {
	let eventSize = "";
	if (event.maxAttendees) {
		eventSize =
			" (" +
			(Object.keys(event.attendees).length + 1) +
			" / " +
			event.maxAttendees +
			")";
	}
	const newTitle = event.title + " @" + event.host + eventSize;
	return newTitle;
};

const BoardgamesPage = props => {
	const eventsStore = useSelector(({ firestore }) => firestore.data.events);
	const events = [];

	const [startDate, setStartDate] = useState(getCurrentDatetime());
	const [endDate, setEndDate] = useState(getCurrentDatetime());
	const [selectedEvent, setSelectedEvent] = useState();

	if (eventsStore) {
		Object.keys(eventsStore).forEach(key => {
			var tempStartDate = new Date(eventsStore[key].start);
			var tempEndDate = new Date(eventsStore[key].end);
			var event = {
				...eventsStore[key],
				id: key,
				start: tempStartDate,
				end: tempEndDate
			};
			events.push(event);
		});
	}

	useFirestoreConnect("events");

	const handleClick = () => {
		props.toggleCreateEventHidden();
	};

	const handleSelectEvent = event => {
		setSelectedEvent(event);
		props.toggleViewEventHidden();
		console.log("handleSelectEvent", event);
	};

	const handleSelectSlot = event => {
		setStartDate(getDatetime(event.start, 12));
		setEndDate(getDatetime(event.end, 12));
		props.toggleCreateEventHidden();
		console.log("handleSelectSlot", event); // FIXME: DELETE
	};

	return (
		<div className="boardgames-page">
			<h2>BOARDGAMES</h2>
			<CustomFab methode={handleClick} />
			<Calendar
				localizer={localizer}
				events={events}
				titleAccessor={event => createTitle(event)}
				startAccessor="start"
				endAccessor="end"
				style={{ height: "75vh" }}
				onSelectEvent={event => handleSelectEvent(event)}
				onSelectSlot={event => handleSelectSlot(event)}
				selectable={true}
				popup
			/>
			<EventCreationDialog startDate={startDate} endDate={endDate} />
			<EventDialog event={selectedEvent} />
		</div>
	);
};

// FIXME: DELETE
const mapStateToProps = createStructuredSelector({
	createEventHidden: selectCreateEventHidden,
	viewEventHidden: selectViewEventHidden,
	events: selectEventCollection,
	currentUser: selectCurrentUser
});

// FIXME: DELETE
const mapDispatchToProps = dispatch => ({
	toggleCreateEventHidden: () => dispatch(toggleCreateEventHidden()),
	toggleViewEventHidden: () => dispatch(toggleViewEventHidden())
});

export default connect(mapStateToProps, mapDispatchToProps)(BoardgamesPage);
