import React from "react";
import {  Grid } from "@material-ui/core";
import { withStyles, createStyles } from "@material-ui/core/styles"

import BePanel from "./be-panel/be-panel.component";
import ShyPanel from "./shy-panel/shy-panel.component";

import { words } from "../../utils/data/data.utils";

const styles = ( {breakpoints}) => createStyles(
	{
	paper: {
		margin: "0.5rem",
		height: "100%",
		display: "flex",
		alignItems: "start",
		justifyContent: "start"
	},
	grid: {
		height: "99%"
	}
});

const BeShyPanel = props => {

	return (
		<Grid
			container
			spacing={0}
			direction="row"
			justify="flex-start"
			alignItems="stretch"
			alignContent="stretch"
			className={props.classes.grid}
		>
			<Grid item xs={6} md={3}>
				<BePanel />
			</Grid>
			<Grid item xs={6} md={3}>
				<ShyPanel words={words} />
			</Grid>
		</Grid>
	);
};

export default withStyles(styles)(BeShyPanel);
