const FIREBASE_CONFIG = {
	apiKey: "AIzaSyD7EdBpVtYaT0NX3ebJiCAZa7yq7ApEA3s",
	authDomain: "be-shy.firebaseapp.com",
	databaseURL: "https://be-shy.firebaseio.com",
	projectId: "be-shy",
	storageBucket: "be-shy.appspot.com",
	messagingSenderId: "41376903032",
	appId: "1:41376903032:web:7bdd4c55239e185e61628d"
};
export default FIREBASE_CONFIG;
