import React, { useEffect, useState } from "react";
import {
	Button,
	TextField,
	Dialog,
	DialogContent,
	DialogTitle,
	DialogActions
} from "@material-ui/core";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { withStyles, createStyles } from "@material-ui/core/styles"


import { toggleCreateEventHidden } from "../../redux/components/components.actions";
import { selectCreateEventHidden } from "../../redux/components/components.selector";
import { createEvent } from "../../redux/events/events.actions";
import { selectCurrentUser } from "../../redux/user/user.selector";

import "./event-creation-dialog.styles.scss";

const styles = ({breakpoints}) => createStyles({
	textfield: {
		margin: "0.5rem"
	}
});

const EventCreationDialog = props => {

	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [host, setHost] = useState("");
	const [start, setStart] = useState(props.startDate);
	const [end, setEnd] = useState(props.endDate);

	useEffect(() => {
		setStart(props.startDate);
		setEnd(props.endDate);
	}, [props]);

	const handleSubmit = () => {
		const event = {
			title: title,
			description: description,
			host: host,
			start: start,
			end: end
		};
		props.createEvent(event);
		props.toggleCreateEventHidden();
	};

	const handleClose = () => {
		setTitle("");
		setDescription("");
		setHost("");
		setStart(props.startDate);
		setEnd(props.endDate);
		props.toggleCreateEventHidden();
	};

	const disableButton = () => {
		return title.length < 1 && host.length < 1;
	};

	/**
	 * Felder
	 * (hidden): confirmed, creator
	 * (sichtbar):
	 * 		TextField: title, description
	 * 		Datum: start, end
	 * 		select: host
	 * 		chipselect: boardgames
	 * Reihenfolge: title, description, host, start, end, boardgames
	 */

	return (
		<div>
			<Dialog
				open={!props.createEventHidden}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
				className="flex flex-col content-center"
			>
				<DialogTitle id="form-dialog-title">
					Brettspiel-Event erstellen
				</DialogTitle>
				<DialogContent>
					<div>
						<TextField
							id="title"
							label="Titel"
							variant="outlined"
							required
							className={props.classes.textfield}
							value={title}
							onChange={event => setTitle(event.target.value)}
						/>
					</div>
					<div>
						<TextField
							id="description"
							label="Beschreibung"
							variant="outlined"
							className={props.classes.textfield}
							value={description}
							onChange={event =>
								setDescription(event.target.value)
							}
							multiline={true}
						/>
					</div>
					<div>
						<TextField
							id="host"
							label="Gastgeber"
							variant="outlined"
							className={props.classes.textfield}
							value={host}
							required={true}
							onChange={event => setHost(event.target.value)}
						/>
					</div>
					<div>
						<TextField
							id="datetime-start"
							label="Start"
							type="datetime-local"
							InputLabelProps={{ shrink: true }}
							className={props.classes.textfield}
							value={start}
							onChange={event => setStart(event.target.value)}
						/>
					</div>
					<div>
						<TextField
							id="datetime-end"
							label="Ende"
							type="datetime-local"
							InputLabelProps={{ shrink: true }}
							className={props.classes.textfield}
							value={end}
							onChange={event => setEnd(event.target.value)}
						/>
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Abbrechen</Button>
					<Button onClick={handleSubmit} disabled={disableButton()}>
						Speichern
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	createEventHidden: selectCreateEventHidden,
	currentUser: selectCurrentUser
});

const mapDispatchToProps = dispatch => ({
	toggleCreateEventHidden: () => dispatch(toggleCreateEventHidden()),
	createEvent: event => dispatch(createEvent(event))
});

export default withStyles(styles)(connect(
	mapStateToProps,
	mapDispatchToProps
)(EventCreationDialog));
