import { ComponentsActionTypes } from "./components.types";

const INITIAL_STATE = {
	events: {
		createEventHidden: true,
		viewEventHidden: true
	},
	user: { loginHidden: true }
};

const componentsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ComponentsActionTypes.TOGGLE_CREATE_EVENT_HIDDEN:
			let tempCreateEventState = { ...state.events };
			tempCreateEventState.createEventHidden = !tempCreateEventState.createEventHidden;
			return {
				...state,
				events: tempCreateEventState
			};

		case ComponentsActionTypes.TOGGLE_VIEW_EVENT_HIDDEN:
			let tempViewEventsState = { ...state.events };
			tempViewEventsState.viewEventHidden = !tempViewEventsState.viewEventHidden;
			return {
				...state,
				events: tempViewEventsState
			};

		case ComponentsActionTypes.TOGGLE_LOGIN_HIDDEN:
			return {
				...state,
				user: { loginHidden: !state.user.loginHidden }
			};

		default:
			return state;
	}
};

export default componentsReducer;
