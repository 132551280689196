import React from "react";
import { Link } from "react-router-dom";

import "./footer.styles.scss";

const Footer = () => (
	<div className="footer">
		<div className="options">
			<Link className="option" to="/privacy-policy">
				Datenschutzerklärung
			</Link>
			<Link className="option" to="/imprint">
				Impressum
			</Link>
		</div>
	</div>
);

export default Footer;
