export const textOne = [
	{
		key: "1",
		type: "h1",
		text: "Hey there!",
	},
	{
		key: "2",
		type: "p",
		text: "Willkommen auf meiner kleinen, eigenen Seite.",
	},
	{
		key: "3",
		type: "p",
		text:
			"Ich bin Steffen, ein neugieriger Geist, der immer gerne neue Dinge ausprobiert.",
	},
	{
		key: "4",
		type: "p",
		text:
			"Von neuen Technologien, neuen Erkenntnissen und allem möglichen spannenden Dingen.",
	},
	{
		key: "5",
		type: "p",
		text:
			"Ich versuche, diese Einstellung in viele Aspekte einfließen zu lassen.",
	},
	{
		key: "6",
		type: "p",
		text:
			"Diese Website ist ein Teil meiner Lernerfahrung, in der ich meine Kenntnisse in den Bereichen WebDev, JavaScript, React und Co. erweitere.",
	},
];

export const textTwo = [
	{
		key: "1",
		type: "p",
		text:
			"Im Moment studiere ich Wirtschaftsinformatik und beschäftige mich in diesem und nebenbei mit dem Gebiet der Data Science.",
	},
	{
		key: "2",
		type: "p",
		text: "Zusätzlich lerne ich unterschiedliche Programmiersprachen.",
	},
	{
		key: "3",
		type: "p",
		text:
			"Ich bin weit entfernt davon, dass ich eine Sprache wirklich gemeistert habe, kenne mich aber zumindest mit einigen aus.",
	},
	{
		key: "4",
		type: "p",
		text: "Zu diesen zählen: Java, C#, Python, JavaScript.",
	},
	{
		key: "5",
		type: "p",
		text: "",
	},
	{
		key: "6",
		type: "p",
		text: "",
	},
];

export const textThree = [
	{
		key: "1",
		type: "p",
		text: "",
	},
	{
		key: "2",
		type: "p",
		text: "",
	},
	{
		key: "3",
		type: "p",
		text: "",
	},
	{
		key: "4",
		type: "p",
		text: "",
	},
	{
		key: "5",
		type: "p",
		text: "Diese Figuren sind von den p5.js Beispielen adaptiert.",
	},
	{
		key: "6",
		type: "a",
		text: "https://p5js.org/examples/",
	},
];
