import React, { useRef, useEffect } from "react";

function useOutsideNoticer(ref, handleFunction, condition = true) {
	/**
	 * Alert if clicked on outside of element
	 */
	function handleClickOutside(event) {
		if (condition && ref.current && !ref.current.contains(event.target)) {
			handleFunction();
		}
	}

	useEffect(() => {
		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	});
}

/**
 * Component that alerts if you click outside of it
 */
function OutsideNoticer(props) {
	const wrapperRef = useRef(null);
	useOutsideNoticer(wrapperRef, props.handleOutside, props.condition);

	return <div ref={wrapperRef}>{props.children}</div>;
}

export default OutsideNoticer;
