import React from "react";
import p5 from "p5";

class PenroseSketch extends React.Component {
	constructor(props) {
		super(props);
		//p5 instance mode requires a reference on the DOM to mount the sketch
		//So we use react's createRef function to give p5 a reference
		this.myRef = React.createRef();
	}

	// This uses p5's instance mode for sketch creation and namespacing
	Sketch = (p) => {
		let ds;

		// p is a p5-Object
		p.setup = () => {
			p.createCanvas(this.props.width, this.props.height);
			ds = new PenroseLSystem();
			//please, play around with the following line
			ds.simulate(5);
		};

		p.draw = () => {
			p.background("#ffffff");
			ds.render();
		};

		function PenroseLSystem() {
			this.steps = 0;

			//these are axiom and rules for the penrose rhombus l-system
			//a reference would be cool, but I couldn't find a good one
			this.axiom = "[X]++[X]++[X]++[X]++[X]";
			this.ruleW = "YF++ZF----XF[-YF----WF]++";
			this.ruleX = "+YF--ZF[---WF--XF]+";
			this.ruleY = "-WF++XF[+++YF++ZF]-";
			this.ruleZ = "--YF++++WF[+ZF++++XF]--XF";

			//please play around with the following two lines
			this.startLength = 300.0;
			this.theta = p.TWO_PI / 10.0; //36 degrees, try TWO_PI / 6.0, ...
			this.reset();
		}

		PenroseLSystem.prototype.simulate = function (gen) {
			while (this.getAge() < gen) {
				this.iterate(this.production);
			}
		};

		PenroseLSystem.prototype.reset = function () {
			this.production = this.axiom;
			this.drawLength = this.startLength;
			this.generations = 0;
		};

		PenroseLSystem.prototype.getAge = function () {
			return this.generations;
		};

		//apply substitution rules to create new iteration of production string
		PenroseLSystem.prototype.iterate = function () {
			let newProduction = "";

			for (let i = 0; i < this.production.length; ++i) {
				let step = this.production.charAt(i);
				//if current character is 'W', replace current character
				//by corresponding rule
				if (step === "W") {
					newProduction = newProduction + this.ruleW;
				} else if (step === "X") {
					newProduction = newProduction + this.ruleX;
				} else if (step === "Y") {
					newProduction = newProduction + this.ruleY;
				} else if (step === "Z") {
					newProduction = newProduction + this.ruleZ;
				} else {
					//drop all 'F' characters, don't touch other
					//characters (i.e. '+', '-', '[', ']'
					if (step !== "F") {
						newProduction = newProduction + step;
					}
				}
			}

			this.drawLength = this.drawLength * 0.5;
			this.generations++;
			this.production = newProduction;
		};

		//convert production string to a turtle graphic
		PenroseLSystem.prototype.render = function () {
			p.translate(p.width / 2, p.height / 2);

			this.steps += 20;
			if (this.steps > this.production.length) {
				this.steps = this.production.length;
			}

			for (let i = 0; i < this.steps; ++i) {
				let step = this.production.charAt(i);

				//'W', 'X', 'Y', 'Z' symbols don't actually correspond to a turtle action
				if (step === "F") {
					p.stroke(0, 60);
					for (let j = 0; j < this.repeats; j++) {
						p.line(0, 0, 0, -this.drawLength);
						p.noFill();
						p.translate(0, -this.drawLength);
					}
					this.repeats = 1;
				} else if (step === "+") {
					p.rotate(this.theta);
				} else if (step === "-") {
					p.rotate(-this.theta);
				} else if (step === "[") {
					p.push();
				} else if (step === "]") {
					p.pop();
				}
			}
		};
	};

	componentDidMount() {
		//We create a new p5 object on component mount, feed it
		this.myP5 = new p5(this.Sketch, this.myRef.current);
	}

	render() {
		return (
			//This div will contain our p5 sketch
			<div ref={this.myRef}></div>
		);
	}
}

export default PenroseSketch;
