import { createSelector } from "reselect";

const selectComponents = state => state.components;

export const selectCreateEventHidden = createSelector(
	[selectComponents],
	component => component.events.createEventHidden
);

export const selectLoginHidden = createSelector(
	[selectComponents],
	component => component.user.loginHidden
);

export const selectViewEventHidden = createSelector(
	[selectComponents],
	component => component.events.viewEventHidden
);
