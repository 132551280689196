import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { auth } from "../../firebase/firebase.utils";

import { selectCurrentUser } from "../../redux/user/user.selector";
import { toggleLoginHidden } from "../../redux/components/components.actions";
import { selectLoginHidden } from "../../redux/components/components.selector";
import { ReactComponent as Logo } from "../../assets/shy.svg";

import LoginInDropdown from "../login-dropdown/login-dropdown.component";

import OutsideNoticer from "../../utils/outside-noticer/outside-noticer.utility";

import "./header.styles.scss";

const Header = ({ currentUser, loginHidden, toggleLoginHidden }) => (
	<div className="header">
		<Link to="/" className="logo-container">
			<Logo className="logo" />
		</Link>
		<div className="options">
			{currentUser && (
				<Link className="option" to="/boardgames">
					Boardgames
				</Link>
			)}
			<Link className="option" to="/about">
				About
			</Link>
		</div>
		<div className="login-options">
			{currentUser ? (
				<div className="login-option" onClick={() => auth.signOut()}>
					Sign Out
				</div>
			) : (
				<div className="login-option" onClick={toggleLoginHidden}>
					Sign In
				</div>
			)}
		</div>
		<OutsideNoticer
			handleOutside={toggleLoginHidden}
			condition={!loginHidden}
		>
			{loginHidden ? null : (
				<LoginInDropdown methode={toggleLoginHidden} />
			)}
		</OutsideNoticer>
	</div>
);

const mapStateToProps = createStructuredSelector({
	currentUser: selectCurrentUser,
	loginHidden: selectLoginHidden,
});

const mapDispatchToProps = (dispatch) => ({
	toggleLoginHidden: () => dispatch(toggleLoginHidden()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
