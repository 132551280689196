import React from "react";
import p5 from "p5";

class LSystemsSketch extends React.Component {
	constructor(props) {
		super(props);
		//p5 instance mode requires a reference on the DOM to mount the sketch
		//So we use react's createRef function to give p5 a reference
		this.myRef = React.createRef();
	}

	// This uses p5's instance mode for sketch creation and namespacing
	Sketch = (p) => {
		// TURTLE STUFF:
		let x, y; // the current position of the turtle
		let currentAngle = 0; // which way the turtle is pointing
		let step = 10; // how much the turtle moves with each 'F'
		let angle = 90; // how much the turtle turns with a '-' or '+'

		// LINDENMAYER STUFF (L-SYSTEMS)
		let theString = "A"; // "axiom" or start of the string
		let numLoops = 5; // how many iterations to pre-compute
		let theRules = []; // array for rules
		theRules[0] = ["A", "-BF+AFA+FB-"]; // first rule
		theRules[1] = ["B", "+AF-BFB-FA+"]; // second rule

		let whereInString = 0; // where in the L-system are we?

		// p is a p5-Object
		p.setup = () => {
			p.createCanvas(this.props.width, this.props.height);
			p.background("#ffffff");
			p.stroke(0, 0, 0, 255);

			// start the x and y position at lower-left corner
			x = 0;
			y = p.height - 1;

			// COMPUTE THE L-SYSTEM
			for (let i = 0; i < numLoops; i++) {
				theString = lindenmayer(theString);
			}
		};

		p.draw = () => {
			// draw the current character in the string:
			drawIt(theString[whereInString]);

			// increment the point for where we're reading the string.
			// wrap around at the end.
			whereInString++;
			if (whereInString > theString.length - 1) whereInString = 0;
		};

		// interpret an L-system
		function lindenmayer(s) {
			let outputString = ""; // start a blank output string

			// iterate through 'therules' looking for symbol matches:
			for (let i = 0; i < s.length; i++) {
				let isMatch = 0; // by default, no match
				for (let j = 0; j < theRules.length; j++) {
					if (s[i] === theRules[j][0]) {
						outputString += theRules[j][1]; // write substitution
						isMatch = 1; // we have a match, so don't copy over symbol
						break; // get outta this for() loop
					}
				}
				// if nothing matches, just copy the symbol over.
				if (isMatch === 0) outputString += s[i];
			}

			return outputString; // send out the modified string
		}

		// this is a custom function that draws turtle commands
		function drawIt(k) {
			if (k === "F") {
				// draw forward
				// polar to cartesian based on step and currentangle:
				let x1 = x + step * p.cos(p.radians(currentAngle));
				let y1 = y + step * p.sin(p.radians(currentAngle));
				p.line(x, y, x1, y1); // connect the old and the new

				// update the turtle's position:
				x = x1;
				y = y1;
			} else if (k === "+") {
				currentAngle += angle; // turn left
			} else if (k === "-") {
				currentAngle -= angle; // turn right
			}

			// draw the stuff:
			p.fill(0, 0, 0, 1);
			//p.ellipse(x, y, radius, radius);
			p.ellipse(x, y, 3, 3);
		}
	};

	componentDidMount() {
		//We create a new p5 object on component mount, feed it
		this.myP5 = new p5(this.Sketch, this.myRef.current);
	}

	render() {
		return (
			//This div will contain our p5 sketch
			<div ref={this.myRef}></div>
		);
	}
}

export default LSystemsSketch;
