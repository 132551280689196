import React from "react";

import "./login-dropdown.styles.scss";

import FormInput from "../form-input/form-input.component";
import CustomButton from "../custom-button/custom-button.component";

import { auth } from "../../firebase/firebase.utils";

class LoginInDropdown extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			email: "",
			password: ""
		};
	}

	handleSubmit = async event => {
		event.preventDefault();

		const { email, password } = this.state;

		try {
			await auth.signInWithEmailAndPassword(email, password);
			this.setState({ email: "", password: "" });
		} catch (error) {
			console.error("Could not log user in", error);
		}
	};

	handleChange = event => {
		const { value, name } = event.target;

		this.setState({ [name]: value });
	};

	render() {
		return (
			<div className="login">
				<form
					onSubmit={async event => {
						await this.handleSubmit(event);
						this.props.methode();
					}}
				>
					<FormInput
						name="email"
						type="email"
						value={this.state.email}
						handleChange={this.handleChange}
						label="Email"
						autoComplete="email"
						required
					/>
					<FormInput
						name="password"
						type="password"
						value={this.state.password}
						handleChange={this.handleChange}
						label="Password"
						autoComplete="current-password"
						required
					/>
					<div className="button">
						<CustomButton type="submit">Sign In</CustomButton>
					</div>
				</form>
			</div>
		);
	}
}

export default LoginInDropdown;
