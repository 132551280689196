import React, { useEffect, useState } from "react";
import {
	Button,
	TextField,
	Dialog,
	DialogContent,
	DialogTitle,
	DialogActions
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { toggleViewEventHidden } from "../../redux/components/components.actions";
import { selectViewEventHidden } from "../../redux/components/components.selector";

import { getDatetime } from "../../utils/data/service.utils";

const useStyles = makeStyles({
	textfield: {
		margin: "0.5rem"
	}
});

const EventDialog = props => {
	const classes = useStyles(props);

	const [title, setTitle] = useState();
	const [description, setDescription] = useState();
	const [host, setHost] = useState();
	const [start, setStart] = useState();
	const [end, setEnd] = useState();

	useEffect(() => {
		if (props.event) {
			setTitle(props.event.title);
			setDescription(props.event.description);
			setHost(props.event.host);
			setStart(getDatetime(props.event.start));
			setEnd(getDatetime(props.event.end));
		}
	}, [props]);

	const handleOk = () => {
		props.toggleViewEventHidden();
	};

	/**
	 * Felder
	 * (hidden): confirmed, creator Felder
	 * (sichtbar): description, title Datum: start, end select: host chipselect: boardgames
	 * Reihenfolge: title, description, host, start, end, boardgames
	 */

	return (
		<div>
			<Dialog
				open={!props.viewEventHidden}
				onClose={props.toggleViewEventHidden}
				aria-labelledby="form-dialog-title"
				className="flex flex-col content-center"
			>
				<DialogTitle id="form-dialog-title">{title}</DialogTitle>
				<DialogContent>
					<div>
						<TextField
							id="description"
							label="Beschreibung"
							variant="standard"
							className={classes.textfield}
							value={description}
							onChange={event =>
								setDescription(event.target.value)
							}
							multiline={true}
							disabled={true}
						/>
					</div>
					<div>
						<TextField
							id="host"
							label="Gastgeber"
							variant="standard"
							className={classes.textfield}
							value={host}
							onChange={event => setHost(event.target.value)}
							disabled={true}
						/>
					</div>
					<div>
						<TextField
							id="datetime-start"
							label="Start"
							type="datetime-local"
							InputLabelProps={{ shrink: true }}
							className={classes.textfield}
							value={start}
							onChange={event => setStart(event.target.value)}
							disabled={true}
						/>
					</div>
					<div>
						<TextField
							id="datetime-end"
							label="Ende"
							type="datetime-local"
							InputLabelProps={{ shrink: true }}
							className={classes.textfield}
							value={end}
							onChange={event => setEnd(event.target.value)}
							disabled={true}
						/>
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleOk}>OK</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	viewEventHidden: selectViewEventHidden
});

const mapDispatchToProps = dispatch => ({
	toggleViewEventHidden: () => dispatch(toggleViewEventHidden())
});

export default connect(mapStateToProps, mapDispatchToProps)(EventDialog);
