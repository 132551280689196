import React from "react";
import { withStyles, createStyles } from "@material-ui/core/styles";

const styles = ({ breakpoints }) =>
	createStyles({
		about: {
			margin: "0.5rem",
			height: "100%",
			display: "flex",
			alignItems: "start",
			justifyContent: "start",
			[breakpoints.down("sm")]: {
				fontSize: "1000px",
				background: "yellow",
			},
			[breakpoints.up("md")]: {
				fontSize: "179px",
				background: "yellow",
			},
			fontWeight: "700",
			color: "pink",
		},
		text: {
			[breakpoints.down("sm")]: {
				fontSize: "1000px",
				background: "yellow",
			},
			[breakpoints.up("md")]: {
				fontSize: "179px",
				background: "yellow",
			},
			fontWeight: "700",
			color: "pink",
		},
		grid: {
			height: "99%",
		},
	});

const AboutPagePanel = (props) => {
	var minStyles = {
		fontSize: "20px",
		color: "#006060",
	};

	var maxStyles = {
		fontSize: "20px",
		color: "#006060",
	};

	var style = () => {
		if (window.innerWidth < 768) {
			return minStyles;
		} else {
			return maxStyles;
		}
	};

	return (
		<div className={styles.about}>
			{props.text.map((element) => {
				if (element.text === "") {
					return <div key={element.key} />;
				} else if (element.type === "a") {
					return <a href={element.text}>{element.text}</a>;
				}
				let htmlElement = React.createElement(
					element.type,
					{
						style: style(),
						key: element.key,
					},
					element.text
				);
				return htmlElement;
			})}
		</div>
	);
};
export default withStyles(styles)(AboutPagePanel);
