export const getCurrentDatetime = offset => {
	let currentDate = new Date();
	if (currentDate.getMinutes() !== 0) {
		currentDate.setHours(currentDate.getHours() + 1);
	}
	if (offset) {
		currentDate.setHours(currentDate.getHours() + offset);
	}
	let customCurrentDate = "";
	let year = "" + currentDate.getFullYear();
	let month = "" + (currentDate.getMonth() + 1);
	let day = "" + currentDate.getDate();
	let hours = "" + currentDate.getHours();
	let minutes = "00";

	if (year.length < 2) {
		year = "0" + year;
	}
	if (month.length < 2) {
		month = "0" + month;
	}
	if (day.length < 2) {
		day = "0" + day;
	}
	if (hours.length < 2) {
		hours = "0" + hours;
	}

	customCurrentDate =
		[year, month, day].join("-") + "T" + hours + ":" + minutes;
	return customCurrentDate;
};

export const getDatetime = (date, offset) => {
	let tempDate = new Date(date);
	if (tempDate.getMinutes() !== 0) {
		tempDate.setHours(tempDate.getHours() + 1);
	}
	if (offset) {
		tempDate.setHours(tempDate.getHours() + offset);
	}
	let customCurrentDate = "";
	let year = "" + tempDate.getFullYear();
	let month = "" + (tempDate.getMonth() + 1);
	let day = "" + tempDate.getDate();
	let hours = "" + tempDate.getHours();
	let minutes = "00";

	if (year.length < 2) {
		year = "0" + year;
	}
	if (month.length < 2) {
		month = "0" + month;
	}
	if (day.length < 2) {
		day = "0" + day;
	}
	if (hours.length < 2) {
		hours = "0" + hours;
	}

	customCurrentDate =
		[year, month, day].join("-") + "T" + hours + ":" + minutes;
	return customCurrentDate;
};
