import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

import FIREBASE_CONFIG from "./firebase.config";

const config = FIREBASE_CONFIG;

export const createUserProfileDocument = async (userAuth, additionalData) => {
	if (!userAuth) return;

	const userRef = firestore.doc(`users/${userAuth.uid}`);
	const snapShot = await userRef.get();

	if (!snapShot.exists) {
		const { displayName, email } = userAuth;
		const createdAt = new Date();

		try {
			await userRef.set({
				displayName,
				email,
				createdAt,
				...additionalData
			});
		} catch (error) {
			console.log("Error creating user", error.message);
		}
	}

	return userRef;
};

export const createEventDocument = async additionalData => {
	const eventsRef = firestore.collection("events/");

	console.log("FIREBASE UTILS", additionalData); // FIXME: DELETE
	try {
		await eventsRef.add({
			title: additionalData.title,
			description: additionalData.description,
			host: additionalData.host,
			start: additionalData.start,
			end: additionalData.end
		});
	} catch (error) {
		console.log("Error creating event", error.message);
	}
};

export const getEventCollection = async userAuth => {
	if (!userAuth) return;

	const eventsRef = firestore.collection("events/");
	const events = {};

	try {
		await eventsRef
			.get()
			.then(querySnapshot =>
				querySnapshot.forEach(doc => {
					console.log("Docs", doc.data());
					events[doc.id] = doc.data();
				})
			)
			.then(() => {
				console.log("WTF", events);
				return events;
			});
	} catch (error) {
		console.log("Error getting events", error.message);
		return events;
	}
};

firebase.initializeApp(config);

export const auth = firebase.auth();
export const firestore = firebase.firestore();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ promt: "select_account" });
export const signInWithGoogle = () => auth.signInWithPopup(provider);

export default firebase;
