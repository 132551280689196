import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";
import firebase from "firebase/app";

import "./App.css";

import HomePage from "./pages/homepage/homepage.page";
import AboutPage from "./pages/about/about.page";
import BoardgamesPage from "./pages/boardgames/boardgames.page";
import Imprint from "./pages/datacompliance/imprint.page";
import PrivacyPolicy from "./pages/datacompliance/privacy-policy.page";

import Header from "./components/header/header.component";
import Footer from "./components/footer/footer.component";

import { auth, createUserProfileDocument } from "./firebase/firebase.utils";

import { setCurrentUser } from "./redux/user/user.actions";
import { selectCurrentUser } from "./redux/user/user.selector";
import { store } from "./redux/store";

const rrfConfig = {
	userProfile: "users",
	useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
};

const rrfProps = {
	firebase,
	config: rrfConfig,
	dispatch: store.dispatch,
	createFirestoreInstance, // <- needed if using firestore
};

class App extends React.Component {
	unsubscribeFromAuth = null;

	componentDidMount() {
		const { setCurrentUser } = this.props;

		this.unsubscribeFromAuth = auth.onAuthStateChanged(async (userAuth) => {
			if (userAuth) {
				const userRef = await createUserProfileDocument(userAuth);

				userRef.onSnapshot((snapShot) => {
					setCurrentUser({
						id: snapShot.id,
						...snapShot.data(),
					});
				});
			} else {
				setCurrentUser(userAuth);
			}
		});
	}

	componentWillUnmount() {
		this.unsubscribeFromAuth();
	}

	render() {
		return (
			<div className="App">
				<ReactReduxFirebaseProvider {...rrfProps}>
					<Header />
					<Switch>
						<Route
							exact
							path="/boardgames"
							render={() =>
								this.props.currentUser ? (
									<BoardgamesPage />
								) : (
									<Redirect to="/" />
								)
							}
						/>
						<Route exact path="/about" component={AboutPage} />
						<Route
							exact
							path="/mail"
							component={() => {
								window.location.href =
									"https://www.hytrek.eu/mail";
								return null;
							}}
						/>
						<Route exact path="/imprint" component={Imprint} />
						<Route
							exact
							path="/privacy-policy"
							component={PrivacyPolicy}
						/>
						<Route path="/" component={HomePage} />
					</Switch>
					<Footer />
				</ReactReduxFirebaseProvider>
			</div>
		);
	}
}

const mapStateToProps = createStructuredSelector({
	currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
	setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
