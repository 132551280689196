import { ComponentsActionTypes } from "./components.types";

export const toggleCreateEventHidden = () => ({
	type: ComponentsActionTypes.TOGGLE_CREATE_EVENT_HIDDEN
});

export const toggleLoginHidden = () => ({
	type: ComponentsActionTypes.TOGGLE_LOGIN_HIDDEN
});

export const toggleViewEventHidden = () => ({
	type: ComponentsActionTypes.TOGGLE_VIEW_EVENT_HIDDEN
});
