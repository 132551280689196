import React from "react";

import { Fab } from "@material-ui/core";
import { MdAdd } from "react-icons/md";

import "./custom-fab.styles.scss";

const CustomFab = ({ methode }) => (
	<Fab
		size="small"
		color="primary"
		aria-label="add"
		className="fab-container"
		onClick={methode}
	>
		<MdAdd className="fab-icon" />
	</Fab>
);

export default CustomFab;
