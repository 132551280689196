import React from "react";
import p5 from "p5";

class ParticlesSketch extends React.Component {
	constructor(props) {
		super(props);
		//p5 instance mode requires a reference on the DOM to mount the sketch
		//So we use react's createRef function to give p5 a reference
		this.myRef = React.createRef();
	}

	// This uses p5's instance mode for sketch creation and namespacing
	Sketch = (p) => {
		// this class describes the properties of a single particle.
		class Particle {
			// setting the co-ordinates, radius and the
			// speed of a particle in both the co-ordinates axes.
			constructor() {
				this.x = p.random(0, p.width);
				this.y = p.random(0, p.height);
				this.r = p.random(1, 6);
				this.xSpeed = p.random(-2, 2);
				this.ySpeed = p.random(-1, 1.5);
			}

			// creation of a particle.
			createParticle() {
				p.noStroke();
				p.fill(0);
				p.circle(this.x, this.y, this.r);
			}

			// setting the particle in motion.
			moveParticle() {
				if (this.x < 0 || this.x > p.width) this.xSpeed *= -1;
				if (this.y < 0 || this.y > p.height) this.ySpeed *= -1;
				this.x += this.xSpeed;
				this.y += this.ySpeed;
			}

			// this function creates the connections(lines)
			// between particles which are less than a certain distance apart
			joinParticles(particles) {
				particles.forEach((element) => {
					let dis = p.dist(this.x, this.y, element.x, element.y);
					if (dis < 85) {
						p.stroke("rgba(0,0,0,1)");
						p.line(this.x, this.y, element.x, element.y);
					}
				});
			}
		}

		let particles = [];

		// p is a p5-Object
		p.setup = () => {
			p.createCanvas(this.props.width, this.props.height);
			for (let i = 0; i < p.width / 12; i++) {
				particles.push(new Particle());
			}
		};

		p.draw = () => {
			p.background("#ffffff");
			for (let i = 0; i < particles.length; i++) {
				particles[i].createParticle();
				particles[i].moveParticle();
				particles[i].joinParticles(particles.slice(i));
			}
		};
	};

	componentDidMount() {
		//We create a new p5 object on component mount, feed it
		this.myP5 = new p5(this.Sketch, this.myRef.current);
	}

	render() {
		return (
			//This div will contain our p5 sketch
			<div ref={this.myRef}></div>
		);
	}
}

export default ParticlesSketch;
