import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";

import userReducer from "./user/user.reducer";
import componentsReducer from "./components/components.reducers";
import eventReducer from "./events/events.reducers";

const persistConfig = {
	key: "root",
	storage,
	whitelist: ["cart"]
};

const rootReducer = combineReducers({
	user: userReducer,
	components: componentsReducer,
	events: eventReducer,
	firebase: firebaseReducer,
	firestore: firestoreReducer
});

export default persistReducer(persistConfig, rootReducer);
