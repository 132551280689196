export const words = [
	"kind",
	"smart",
	"silly",
	"hearty",
	"loud",
	"quiet",
	"ambitious",
	"curious",
	"quizzical",
	"warm",
	"cuddly",
	"childish",
	"yourself",
	"exaggerated",
	"grateful",
	"happy",
	"reflected",
	"shy"
];
