import { EventActionTypes } from "./events.types";

export const getEvents = userAuth => ({
	type: EventActionTypes.GET_EVENTS,
	payload: { userAuth: userAuth }
});

export const createEvent = event => ({
	type: EventActionTypes.CREATE_EVENT,
	payload: { event: event }
});
