import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import { withStyles, createStyles } from "@material-ui/core/styles";

import AboutPagePanel from "../../components/about-panel/about-panel.component";

import ParticlesSketch from "../../sketches/particles.sketch";
import PenroseSketch from "../../sketches/penrose.sketch";
import LSystemsSketch from "../../sketches/l-systems.sketch";

import {
	textOne,
	textTwo,
	textThree,
} from "../../utils/data/about-texts.utils.js";

import { Grid, Paper } from "@material-ui/core";

const styles = ({ breakpoints }) =>
	createStyles({
		paperText: {
			margin: "0.5rem",
			padding: "0.5rem",
			height: "100%",
			display: "flex",
			alignItems: "start",
			justifyContent: "start",
			overflow: "hidden",
		},
		paperGraphic: {
			margin: "0.5rem",
			height: "100%",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			overflow: "hidden",
		},
		grid: {
			height: "90%",
		},
		gridItemText: {
			overflow: "hidden",
		},
		gridItemGraphic: {
			overflow: "hidden",
		},
	});

const AboutPage = (props) => {
	// Solution by Shane @ https://stackoverflow.com/questions/49058890/how-to-get-a-react-components-size-height-width-before-render/49058984
	const sketchOne = useRef();
	const sketchTwo = useRef();
	const sketchThree = useRef();

	const [dimensionsOne, setDimensionsOne] = useState({
		width: 230,
		height: 230,
	});
	const [dimensionsTwo, setDimensionsTwo] = useState({
		width: 230,
		height: 230,
	});
	const [dimensionsThree, setDimensionsThree] = useState({
		width: 230,
		height: 230,
	});

	// holds the timer for setTimeout and clearInterval
	let movement_timer = null;

	// the number of ms the window size must stay the same size before the
	// dimension state variable is reset
	const RESET_TIMEOUT = 100;

	const test_dimensions = () => {
		// For some reason targetRef.current.getBoundingClientRect was not available
		// I found this worked for me, but unfortunately I can't find the
		// documentation to explain this experience
		if (sketchOne.current) {
			setDimensionsOne({
				width: sketchOne.current.offsetWidth,
				height: sketchOne.current.offsetHeight,
			});
		}
		if (sketchTwo.current) {
			setDimensionsTwo({
				width: sketchTwo.current.offsetWidth,
				height: sketchTwo.current.offsetHeight,
			});
		}
		if (sketchThree.current) {
			setDimensionsThree({
				width: sketchThree.current.offsetWidth,
				height: sketchThree.current.offsetHeight,
			});
		}
	};

	// This sets the dimensions on the first render
	useLayoutEffect(() => {
		test_dimensions();
	}, []);

	useEffect(() => {
		function handleRezise() {
			test_dimensions();
		}

		window.addEventListener("resize", handleRezise);
	});

	// every time the window is resized, the timer is cleared and set again
	// the net effect is the component will only reset after the window size
	// is at rest for the duration set in RESET_TIMEOUT.  This prevents rapid
	// redrawing of the component for more complex components such as charts
	window.addEventListener("resize", () => {
		clearInterval(movement_timer);
		movement_timer = setTimeout(test_dimensions, RESET_TIMEOUT);
	});

	return (
		<Grid
			container
			spacing={0}
			direction="row"
			justify="flex-start"
			alignItems="stretch"
			alignContent="stretch"
			className={props.classes.grid}
		>
			<Grid item xs={6} md={8} className={props.classes.gridItemText}>
				<Paper elevation={3} className={props.classes.paperText}>
					<AboutPagePanel text={textOne} />
				</Paper>
			</Grid>
			<Grid
				ref={sketchOne}
				item
				xs={6}
				md={4}
				className={props.classes.gridItemGraphic}
			>
				<Paper elevation={3} className={props.classes.paperGraphic}>
					<ParticlesSketch
						width={dimensionsOne.width - 30}
						height={dimensionsOne.height - 30}
					/>
				</Paper>
			</Grid>

			<Grid
				ref={sketchTwo}
				item
				xs={6}
				md={4}
				className={props.classes.gridItemGraphic}
			>
				<Paper elevation={3} className={props.classes.paperGraphic}>
					<PenroseSketch
						width={dimensionsTwo.width - 30}
						height={dimensionsTwo.height - 30}
					/>
				</Paper>
			</Grid>
			<Grid item xs={6} md={8} className={props.classes.gridItemText}>
				<Paper elevation={3} className={props.classes.paperText}>
					<AboutPagePanel text={textTwo} />
				</Paper>
			</Grid>

			<Grid item xs={8} md={8} className={props.classes.gridItemText}>
				<Paper elevation={3} className={props.classes.paperText}>
					<AboutPagePanel text={textThree} />
				</Paper>
			</Grid>
			<Grid
				ref={sketchThree}
				item
				xs={6}
				md={4}
				className={props.classes.gridItemGraphic}
			>
				<Paper elevation={3} className={props.classes.paperGraphic}>
					<LSystemsSketch
						width={dimensionsThree.width - 30}
						height={dimensionsThree.height - 30}
					/>
				</Paper>
			</Grid>
		</Grid>
	);
};
export default withStyles(styles)(AboutPage);
