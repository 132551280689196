import React from "react";
import { Paper } from "@material-ui/core";
import { withStyles, createStyles } from "@material-ui/core/styles"

const styles = ( {breakpoints} ) => createStyles(
	{
	paper: {
		margin: "0rem",
		padding: "auto",
		height: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	text: {
		[breakpoints.down('sm')]:{
			fontSize: "120px"},
		[breakpoints.up('md')]:{
			fontSize: "179px"},
		color: "#006060"
	}
});

const BePanel = props => {

	return (
		<Paper elevation={5} className={props.classes.paper}>
			<h1 className={props.classes.text}>BE</h1>
		</Paper>
	);
};

export default withStyles(styles)(BePanel);
