import React from "react";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
	paper: {
		margin: "2rem 2rem 4rem 2rem",
		padding: "10px 40px"
	}
});

const PrivacyPolicy = props => {
	const classes = useStyles(props);

	return (
		<Paper elevation={5} className={classes.paper}>
			<h1>Datenschutzerklärung</h1>
			<h2>1. Datenschutz auf einen Blick</h2>
			<h3>Allgemeine Hinweise</h3>
			<p>
				Die folgenden Hinweise geben einen einfachen Überblick darüber,
				was mit Ihren personenbezogenen Daten passiert, wenn Sie diese
				Website besuchen. Personenbezogene Daten sind alle Daten, mit
				denen Sie persönlich identifiziert werden können. Ausführliche
				Informationen zum Thema Datenschutz entnehmen Sie unserer unter
				diesem Text aufgeführten Datenschutzerklärung.
			</p>
			<h3>Datenerfassung auf dieser Website</h3>
			<p>
				<strong>
					Wer ist verantwortlich für die Datenerfassung auf dieser
					Website?
				</strong>
			</p>
			<p>
				Die Datenverarbeitung auf dieser Website erfolgt durch den
				Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum
				dieser Website entnehmen.
			</p>
			<p>
				<strong>Wie erfassen wir Ihre Daten?</strong>
			</p>
			<p>
				Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
				mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie
				in ein Kontaktformular eingeben.
			</p>
			<p>
				Andere Daten werden automatisch oder nach Ihrer Einwilligung
				beim Besuch der Website durch unsere IT-Systeme erfasst. Das
				sind vor allem technische Daten (z. B. Internetbrowser,
				Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung
				dieser Daten erfolgt automatisch, sobald Sie diese Website
				betreten.
			</p>
			<p>
				<strong>Wofür nutzen wir Ihre Daten?</strong>
			</p>
			<p>
				Ein Teil der Daten wird erhoben, um eine fehlerfreie
				Bereitstellung der Website zu gewährleisten. Andere Daten können
				zur Analyse Ihres Nutzerverhaltens verwendet werden.
			</p>
			<p>
				<strong>Welche Rechte haben Sie bezüglich Ihrer Daten?</strong>
			</p>
			<p>
				Sie haben jederzeit das Recht, unentgeltlich Auskunft über
				Herkunft, Empfänger und Zweck Ihrer gespeicherten
				personenbezogenen Daten zu erhalten. Sie haben außerdem ein
				Recht, die Berichtigung oder Löschung dieser Daten zu verlangen.
				Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben,
				können Sie diese Einwilligung jederzeit für die Zukunft
				widerrufen. Außerdem haben Sie das Recht, unter bestimmten
				Umständen die Einschränkung der Verarbeitung Ihrer
				personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen
				ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
			</p>
			<p>
				Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie
				sich jederzeit unter der im Impressum angegebenen Adresse an uns
				wenden.
			</p>
			<h2>2. Hosting und Content Delivery Networks (CDN)</h2>
			<h3>Externes Hosting</h3>
			<p>
				Diese Website wird bei einem externen Dienstleister gehostet
				(Hoster). Die personenbezogenen Daten, die auf dieser Website
				erfasst werden, werden auf den Servern des Hosters gespeichert.
				Hierbei kann es sich v. a. um IP-Adressen, Kontaktanfragen,
				Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten,
				Namen, Webseitenzugriffe und sonstige Daten, die über eine
				Website generiert werden, handeln.
			</p>
			<p>
				Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung
				gegenüber unseren potenziellen und bestehenden Kunden (Art. 6
				Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen
				und effizienten Bereitstellung unseres Online-Angebots durch
				einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
			</p>
			<p>
				Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies
				zur Erfüllung seiner Leistungspflichten erforderlich ist und
				unsere Weisungen in Bezug auf diese Daten befolgen.
			</p>
			<p>
				Details entnehmen Sie der Datenschutzerklärung des
				Hosting-Providers unter folgendem Link:
				https://www.netcup.de/kontakt/datenschutzerklaerung.php.
			</p>
			<p>
				<strong>
					Abschluss eines Vertrages über Auftragsverarbeitung
				</strong>
			</p>
			<p>
				Um die datenschutzkonforme Verarbeitung zu gewährleisten, haben
				wir einen Vertrag über Auftragsverarbeitung mit unserem Hoster
				geschlossen.
			</p>
			<h2>3. Allgemeine Hinweise und Pflichtinformationen</h2>
			<h3>Datenschutz</h3>
			<p>
				Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
				Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
				vertraulich und entsprechend der gesetzlichen
				Datenschutzvorschriften sowie dieser Datenschutzerklärung.
			</p>
			<p>
				Wenn Sie diese Website benutzen, werden verschiedene
				personenbezogene Daten erhoben. Personenbezogene Daten sind
				Daten, mit denen Sie persönlich identifiziert werden können. Die
				vorliegende Datenschutzerklärung erläutert, welche Daten wir
				erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu
				welchem Zweck das geschieht.
			</p>
			<p>
				Wir weisen darauf hin, dass die Datenübertragung im Internet (z.
				B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen
				kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch
				Dritte ist nicht möglich.
			</p>
			<h3>Hinweis zur verantwortlichen Stelle </h3>
			<p>
				Die verantwortliche Stelle für die Datenverarbeitung auf dieser
				Website ist:
			</p>
			<ul>
				<p>Steffen Hytrek</p>
				<p>Hannover</p>
				<p>steffen@hytrek.eu</p>
			</ul>
			<p>
				Verantwortliche Stelle ist die natürliche oder juristische
				Person, die allein oder gemeinsam mit anderen über die Zwecke
				und Mittel der Verarbeitung von personenbezogenen Daten (z. B.
				Namen, E-Mail-Adressen o. Ä.) entscheidet.
			</p>
			<h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung </h3>
			<p>
				Viele Datenverarbeitungsvorgänge sind nur mit Ihrer
				ausdrücklichen Einwilligung möglich. Sie können eine bereits
				erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine
				formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der
				bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf
				unberührt.
			</p>
			<h3>
				Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen
				sowie gegen Direktwerbung (Art. 21 DSGVO)
			</h3>
			<p>
				Wenn die Datenverarbetung auf Grundlage von Art. 6 Abs. 1 lit. e
				oder f DSGVO erfolgt, haben Sie jederzeit das Recht, aus
				Gründen, die sich aus Ihrer besonderen Situation ergeben, gegen
				die Verarbeitung Ihrer personenbezogenen Daten Widerspruch
				einzulegen; dies gilt auch für ein auf diese Bestimmungen
				gestütztes Profiling. Die jeweilige Rechtsgrundlage, auf denen
				eine Verarbeitung beruht, entnehmen Sie dieser
				Datenschutzerklärung. Wenn Sie Widerspruch einlegen, werden wir
				Ihre betroffenen personenbezogenen Daten nicht mehr verarbeiten,
				es sei denn, wir können zwingende schutzwürdige Gründe für die
				Verarbeitung nachweisen, die Ihre Interessen, Rechte und
				Freiheiten überwiegen oder die Verarbeitung dient der
				Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
				(Widerspruch nach Art. 21 Abs. 1 DSGVO). Werden Ihre
				personenbezogenen Daten verarbeitet, um Direktwerbung zu
				betreiben, so haben Sie das Recht, jederzeit Widerspruch gegen
				die Verarbeitung Sie betreffender personenbezogener Daten zum
				Zwecke derartiger Werbung einzulegen; dies gilt auch für das
				Profiling, soweit es mit solcher Direktwerbung in Verbindung
				steht. Wenn Sie widersprechen, werden Ihre personenbezogenen
				Daten anschließend nicht mehr zum Zwecke der Direktwerbung
				verwendet (Widerspruch nach Art. 21 Abs. 2 DSGVO).
			</p>
			<h3>Beschwerderecht bei der zuständigen Aufsichtsbehörde </h3>
			<p>
				Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
				Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
				Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres
				Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu. Das
				Beschwerderecht besteht unbeschadet anderweitiger
				verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
			</p>
			<h3>Recht auf Datenübertragbarkeit </h3>
			<p>
				Sie haben das Recht, Daten, die wir auf Grundlage Ihrer
				Einwilligung oder in Erfüllung eines Vertrags automatisiert
				verarbeiten, an sich oder an einen Dritten in einem gängigen,
				maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die
				direkte Übertragung der Daten an einen anderen Verantwortlichen
				verlangen, erfolgt dies nur, soweit es technisch machbar ist.
			</p>
			<h3>SSL- bzw. TLS-Verschlüsselung </h3>
			<p>
				Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
				Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
				oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine
				SSL- bzw. TLS- Verschlüsselung. Eine verschlüsselte Verbindung
				erkennen Sie daran, dass die Adresszeile des Browsers von
				„http://“ auf „https://“ wechselt und an dem Schloss-Symbol in
				Ihrer Browserzeile.
			</p>
			<p>
				Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
				Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
				werden.
			</p>
			<h3>Auskunft, Löschung und Berichtigung </h3>
			<p>
				Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
				jederzeit das Recht auf unentgeltliche Auskunft über Ihre
				gespeicherten personenbezogenen Daten, deren Herkunft und
				Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht
				auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu
				weiteren Fragen zum Thema personenbezogene Daten können Sie sich
				jederzeit unter der im Impressum angegebenen Adresse an uns
				wenden.
			</p>
			<h3>Recht auf Einschränkung der Verarbeitung </h3>
			<p>
				Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
				personenbezogenen Daten zu verlangen. Hierzu können Sie sich
				jederzeit unter der im Impressum angegebenen Adresse an uns
				wenden. Das Recht auf Einschränkung der Verarbeitung besteht in
				folgenden Fällen:
			</p>
			<ul>
				<li>
					Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
					personenbezogenen Daten bestreiten, benötigen wir in der
					Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung
					haben Sie das Recht, die Einschränkung der Verarbeitung
					Ihrer personenbezogenen Daten zu verlangen. Wenn die
					Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
					geschah/geschieht, können Sie statt der Löschung die
					Einschränkung der Datenverarbeitung verlangen. Wenn wir Ihre
					personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch
					zur Ausübung, Verteidigung oder Geltendmachung von
					Rechtsansprüchen benötigen, haben Sie das Recht, statt der
					Löschung die Einschränkung der Verarbeitung Ihrer
					personenbezogenen Daten zu verlangen.
				</li>
				<li>
					Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO
					eingelegt haben, muss eine Abwägung zwischen Ihren und
					unseren Interessen vorgenommen werden. Solange noch nicht
					feststeht, wessen Interessen überwiegen, haben Sie das
					Recht, die Einschränkung der Verarbeitung Ihrer
					personenbezogenen Daten zu verlangen.
				</li>
			</ul>
			<p>
				Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
				eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung
				abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung,
				Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz
				der Rechte einer anderen natürlichen oder juristischen Person
				oder aus Gründen eines wichtigen öffentlichen Interesses der
				Europäischen Union oder eines Mitgliedstaats verarbeitet werden.
			</p>
			<h2>4. Datenerfassung auf dieser Website </h2>
			<h3>Server-Log-Dateien </h3>
			<p>
				Der Provider der Seiten erhebt und speichert automatisch
				Informationen in so genannten Server-Log-Dateien, die Ihr
				Browser automatisch an uns übermittelt. Dies sind:
			</p>
			<ul>
				<li>Browsertyp und Browserversion </li>
				<li>verwendetes Betriebssystem</li>
				<li>Referrer</li>
				<li>URL Hostname des zugreifenden Rechners</li>
				<li>Uhrzeit der Serveranfrage </li>
				<li>IP-Adresse</li>
			</ul>
			<p>
				Eine Zusammenführung dieser Daten mit anderen Datenquellen wird
				nicht vorgenommen.
			</p>
			<p>
				Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs.
				1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
				Interesse an der technisch fehlerfreien Darstellung und der
				Optimierung seiner Website – hierzu müssen die Server-Log-Files
				erfasst werden.
			</p>
			<h2>5. Plugins und Tools </h2>
			<h3>Google Web Fonts </h3>
			<p>
				Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten
				so genannte Web Fonts, die von Google bereitgestellt werden. Die
				Google Fonts sind lokal installiert. Eine Verbindung zu Servern
				von Google findet dabei nicht statt.
			</p>
			<p>
				Weitere Informationen zu Google Web Fonts finden Sie unter
				https://developers.google.com/fonts/faq und in der
				Datenschutzerklärung von Google:
				https://policies.google.com/privacy?hl=de.
			</p>
			<h3>OpenStreetMap </h3>
			<p>
				Wir nutzen den Kartendienst von OpenStreetMap (OSM). Anbieterin
				ist die Open-Street-Map Foundation (OSMF), 132 Maney Hill Road,
				Sutton Coldfield, West Midlands, B72 1JU, United Kingdom.
			</p>
			<p>
				Wenn Sie eine Website besuchen, auf der OpenStreetMap
				eingebunden ist, werden u. a. Ihre IP-Adresse und weitere
				Informationen über Ihr Verhalten auf dieser Website an die OSMF
				weitergeleitet. OpenStreetMap speichert hierzu unter Umständen
				Cookies in Ihrem Browser. Das sind Textdateien, die auf Ihrem
				Computer gespeichert werden und die eine Analyse der Benutzung
				der Website durch Sie ermöglichen. Sie können die Speicherung
				der Cookies durch eine entsprechende Einstellung Ihrer
				Browser-Software verhindern; wir weisen Sie jedoch darauf hin,
				dass Sie in diesem Fall gegebenenfalls nicht sämtliche
				Funktionen dieser Website vollumfänglich werden nutzen können.
			</p>
			<p>
				Ferner kann Ihr Standort erfasst werden, wenn Sie dies in Ihren
				Geräteeinstellungen – z. B. auf Ihrem Handy – zugelassen haben.
				Der Anbieter dieser Seite hat keinen Einfluss auf diese
				Datenübertragung. Details entnehmen Sie der Datenschutzerklärung
				von OpenStreetMap unter folgendem Link:
				https://wiki.osmfoundation.org/wiki/Privacy_Policy.
			</p>
			<p>
				Die Nutzung von OpenStreetMap erfolgt im Interesse einer
				ansprechenden Darstellung unserer Online- Angebote und einer
				leichten Auffindbarkeit der von uns auf der Website angegebenen
				Orte. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6
				Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende Einwilligung
				abgefragt wurde (z. B. eine Einwilligung zur Speicherung von
				Cookies), erfolgt die Verarbeitung ausschließlich auf Grundlage
				von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit
				widerrufbar.
			</p>
		</Paper>
	);
};

export default PrivacyPolicy;
