import React, { useEffect, useState } from "react";
import { Paper } from "@material-ui/core";
import { withStyles, createStyles } from "@material-ui/core/styles"

const styles = ({breakpoints}) => createStyles(
	{
	paper: {
		margin: "0rem",
		height: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		textAlign: "center",
		overflow: "hidden"
	},
	textContainer: {
		display: "table",
		justifyContent: "center",
		textAlign: "center"
	},
	text: {
		[breakpoints.down('sm')]:{
			fontSize: "large",
			marginBlockStart: "0.5rem",
			marginBlockEnd: "0.5rem"
		},
		[breakpoints.up('md')]:{
			fontSize: "large",
			marginBlockStart: "1rem",
			marginBlockEnd: "1rem"
		}
		
	},
	textChosen: {
		[breakpoints.down('sm')]:{
			fontSize: "x-large",
			fontWeight: "700",
			marginBlockStart: "0.5rem",
			marginBlockEnd: "0.5rem"
		},
		[breakpoints.up('md')]:{
			fontSize: "xxx-large",
			fontWeight: "700",
			marginBlockStart: "1rem",
			marginBlockEnd: "1rem"
		},
		color: "#006060",
		
	}
});

const ShyPanel = props => {

	const [messageIndex, setMessageIndex] = useState(0);

	useEffect(() => {
		let timeout;
		timeout = setTimeout(() => {
			messageIndex < props.words.length - 1
				? setMessageIndex(messageIndex + 1)
				: setMessageIndex(0);
		}, 1000);

		return () => {
			clearTimeout(timeout);
		};
	}, [props, messageIndex]);

	return (
		<Paper className={props.classes.paper}>
			<div>
				{props.words.map((word, index) => {
					if (index === messageIndex) {
						return (
							<p
								key={"div-" + word}
								className={props.classes.textChosen}
							>
								{word}
							</p>
						);
					} else {
						return (
							<p 
								key={"div-" + word}
								className={props.classes.text}
							>
								{word}
							</p>
						);
					}
				})}
			</div>
		</Paper>
	);
};

export default withStyles(styles)(ShyPanel);
