import React from "react";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
	paper: {
		margin: "2rem 2rem 4rem 2rem",
		padding: "10px 40px",
	},
});

const Imprint = (props) => {
	const classes = useStyles(props);

	return (
		<Paper elevation={5} className={classes.paper}>
			<h2>Impressum</h2>
			<ul>
				<p>Steffen Hytrek</p>
				<p>Hannover</p>
				<a href="mailto:steffen@hytrek.eu">steffen@hytrek.eu</a>
			</ul>
			<p>
				Diese Webpräsenz ist ein privates Projekt, was keinerlei
				kommerziellen oder meinungsbildnerische Intentionen verfolgt.
			</p>
			<p>
				Somit dient dieses Impressum als Möglichkeit der Kontaktaufnahme
				bei Fragen oder Anregungen.
			</p>
		</Paper>
	);
};

export default Imprint;
